<template>
    <div class="short-link">
        <el-form
            ref="linkForm"
            :inline="true"
            :model="form"
            class="form-inline"
            label-width="80px"
            size="mini"
            label-position="left"
        >
            <el-row>
                <el-form-item label="文件名" prop="name">
                    <el-input
                        v-model="form.name"
                        placeholder="文件名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="链接地址" prop="link">
                    <el-input
                        v-model="form.link"
                        placeholder="链接地址"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTable"
                        >查询</el-button
                    >
                    <el-button
                        @click="resetForm"
                        icon="el-icon-refresh-right"
                    ></el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <div class="button-ctrl">
            <el-button
                type="success"
                @click="showCreateDialog"
                icon="el-icon-plus"
                size="mini"
                >快速创建</el-button
            >
            <el-button
                type="success"
                @click="showShortLinkDialog('add')"
                icon="el-icon-plus"
                size="mini"
                >新增</el-button
            >
        </div>
        <TableList
            class="short-link-table"
            ref="shortLinkTable"
            :query="form"
            :columns="table.columms"
            :tableData.sync="table.tableData"
            :page.sync="table.page"
            :doHandler="table.doHandler"
            fullHeight
            border
        >
            <template slot="effect_type" slot-scope="{ value }">
                {{ value == 0 ? "时间" : "次数" }}
            </template>
            <template slot="effect_count" slot-scope="{ value }">
                {{ value > 0 ? value : "-" }}
            </template>
            <template slot="access_count" slot-scope="{ value }">
                {{ value }}
            </template>
            <template slot="action" slot-scope="{ data }">
                <div class="column-scope-action">
                    <el-link
                        type="primary"
                        icon="el-icon-document-copy"
                        @click="copyShortLink(data)"
                        >复制短链接</el-link
                    >
                    <el-link
                        type="primary"
                        icon="el-icon-document-copy"
                        @click="showShortLinkDialog('update', data)"
                        >编辑</el-link
                    >
                    <el-link
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteShortLink(data)"
                        >删除</el-link
                    >
                </div>
            </template>
        </TableList>
        <CreateDialog
            :visible.sync="dialog.create.visible"
            @reload="queryTable"
        />
        <ShortLinkDialog
            :title="dialog.shortLink.title"
            :visible.sync="dialog.shortLink.visible"
            :type="dialog.shortLink.type"
            :data="dialog.shortLink.data"
            @reload="queryTable"
        />
    </div>
</template>
<script>
import copy from "copy-to-clipboard";

import TableList from "@/components/table-list";
import CreateDialog from "./createDialog";
import ShortLinkDialog from "./shortLinkDialog";
import {
    getShortLinkPageApi,
    deleteShortLinkApi
} from "@/api/web/shortLink.js";
import columms from "./columns";
export default {
    name: "ShortLink",
    components: {
        TableList,
        CreateDialog,
        ShortLinkDialog
    },
    data() {
        return {
            form: {
                name: "",
                link: ""
            },
            table: {
                columms,
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                doHandler: getShortLinkPageApi,
                tableData: []
            },
            dialog: {
                shortLink: {
                    visible: false,
                    type: "",
                    title: "",
                    data: undefined
                },
                create: {
                    visible: false
                }
            }
        };
    },
    methods: {
        async callDeleteShortLink(data) {
            return new Promise((resolve, reject) => {
                deleteShortLinkApi(data)
                    .then(() => {
                        this.$notify({
                            title: "成功",
                            message: "删除成功",
                            type: "success"
                        });
                        resolve();
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    })
                    .finally(() => {
                        this.queryTable();
                    });
            });
        },
        queryTable() {
            this.$refs["shortLinkTable"].doQuery();
        },
        resetForm() {
            this.$refs["linkForm"].resetFields();
        },
        showCreateDialog() {
            this.dialog.create.visible = true;
        },
        showShortLinkDialog(type, data) {
            this.dialog.shortLink.visible = true;
            this.dialog.shortLink.type = type;
            this.dialog.shortLink.data = data;
            this.dialog.shortLink.title =
                type === "add"
                    ? "新增短链接"
                    : type === "update"
                    ? "编辑短链接"
                    : "短链接详情";
        },
        deleteShortLink(data) {
            this.$confirm(
                `此操作将永久删除短链接（链接名：${data.name}）, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(async () => {
                    await this.callDeleteShortLink(data);
                })
                .catch(() => {})
                .finally(() => {});
        },
        copyShortLink(data) {
            let shortUrl = data.short_link;
            copy(shortUrl, {
                debug: false,
                message: "Press #{key} to copy"
            });
            this.$notify({
                title: "成功",
                message: "复制成功",
                type: "success"
            });

            // 非Https不能用
            // navigator.clipboard
            //     .writeText(shortUrl)
            //     .then(() => {
            //         this.$notify({
            //             title: "成功",
            //             message: "复制成功",
            //             type: "success"
            //         });
            //     })
            //     .catch(err => {
            //         this.$notify({
            //             title: "失败",
            //             message: err,
            //             type: "error"
            //         });
            //     });
        }
    },
    created() {},
    mounted() {
        this.queryTable();
    }
};
</script>
<style lang="scss" scoped>
.short-link {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    .button-ctrl {
        margin-bottom: 10px;
    }
    .short-link-table {
        flex: 1;
        .column-scope-action {
            > * + * {
                margin-left: 10px;
            }
        }
    }
}
</style>
