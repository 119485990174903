import request from "@/router/axios";

export const getShortLinkPageApi = data => {
    return request({
        url: "/api/web/shortLink/page",
        method: "post",
        data
    });
};

export const createShortLinkApi = data => {
    return request({
        url: "/api/web/shortLink/create",
        method: "post",
        data
    });
};

export const addShortLinkApi = data => {
    return request({
        url: "/api/web/shortLink/insert",
        method: "post",
        data
    });
};

export const updateShortLinkApi = data => {
    return request({
        url: "/api/web/shortLink/update",
        method: "put",
        data
    });
};

export const deleteShortLinkApi = data => {
    return request({
        url: "/api/web/shortLink/delete",
        method: "delete",
        data
    });
};
