<template>
    <el-dialog
        class="create-dialog"
        title="快速创建"
        :visible.sync="isShow"
        width="400px"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <el-form
            ref="createForm"
            :model="form"
            label-width="80px"
            size="mini"
            :rules="rules"
            :key="key"
        >
            <el-form-item label="原地址" prop="old_link">
                <el-input type="textarea" v-model="form.old_link"></el-input>
            </el-form-item>
            <el-form-item label="生效时间" prop="effect_time">
                <el-select v-model="form.effect_time" placeholder="请选择">
                    <el-option
                        v-for="item in effectTimeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" @click="handlerReset">重置</el-button>
            <el-button type="primary" size="mini" @click="handlerSave"
                >保存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import copy from "copy-to-clipboard";
import moment from "moment";
import { createShortLinkApi } from "@/api/web/shortLink.js";

export default {
    name: "ShortLinkDialog",
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        isShow: {
            get: function() {
                return this.visible;
            },
            set: function(v) {
                this.$emit("update:visible", v);
            }
        },
        rules() {
            let { old_link, effect_time } = this.rulesAll;
            let rules = {
                old_link,
                effect_time
            };
            return rules;
        },
        reqParams() {
            let { old_link, effect_time } = this.form;
            let splitEffectTime = effect_time.split("-");

            effect_time = moment()
                .add(parseInt(splitEffectTime[0]), splitEffectTime[1])
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
            return {
                old_link,
                effect_time
            };
        }
    },
    data() {
        return {
            key: 0,
            form: {},
            rulesAll: {
                old_link: [
                    {
                        required: true,
                        message: "请输入原地址",
                        trigger: "blur"
                    }
                ],
                effect_time: [
                    {
                        required: true,
                        message: "请输入生效时间止",
                        trigger: "blur"
                    }
                ]
            },
            effectTimeOptions: [
                { label: "1天", value: "1-d" },
                { label: "1周", value: "1-w" },
                { label: "1个月", value: "1-M" },
                { label: "3个月", value: "3-M" }
            ]
        };
    },
    methods: {
        setData() {
            this.$set(this, "form", {
                old_link: "",
                effect_time: this.effectTimeOptions[0].value
            });
        },
        handlerReset() {
            this.$refs["createForm"].resetFields();
        },
        handlerOpen() {
            this.key++;
            this.setData();
        },
        handlerClose() {
            this.isShow = false;
        },
        handlerSave() {
            this.$refs["createForm"].validate(valid => {
                if (valid) {
                    console.log(createShortLinkApi);
                    createShortLinkApi(this.reqParams)
                        .then(res => {
                            if (res.data.code == 0) {
                                this.$notify({
                                    title: "成功",
                                    message: "保存成功",
                                    type: "success"
                                });
                                this.showShortUrl(res.data.data);
                                this.handlerClose();
                                this.$emit("reload");
                            } else {
                                this.$notify.error({
                                    title: "失败",
                                    message: res.data.msg || "保存失败"
                                });
                            }
                        })
                        .catch(error => {
                            this.$notify.error({
                                title: "异常",
                                message: error
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        showShortUrl(url) {
            const h = this.$createElement;
            this.$msgbox({
                title: "短连接地址",
                message: h(
                    "p",
                    { style: "white-space: normal; overflow-wrap: break-word" },
                    url
                ),
                showCancelButton: true,
                confirmButtonText: "复制并关闭",
                cancelButtonText: "关闭",
                closeOnClickModal: false,
                closeOnPressEscape: false
            })
                .then(() => {
                    copy(url, {
                        debug: false,
                        message: "Press #{key} to copy"
                    });
                    this.$notify({
                        title: "成功",
                        message: "复制成功",
                        type: "success"
                    });
                    // 非Https不能用
                    // navigator.clipboard
                    //     .writeText(url)
                    //     .then(() => {
                    //         this.$notify({
                    //             title: "成功",
                    //             message: "复制成功",
                    //             type: "success"
                    //         });
                    //     })
                    //     .catch(err => {
                    //         this.$notify({
                    //             title: "失败",
                    //             message: err,
                    //             type: "error"
                    //         });
                    //     });
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="scss" scoped>
.el-form .el-form-item {
    .el-input,
    .el-select {
        width: 100%;
    }
}
</style>
