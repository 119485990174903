export default [
    {
        prop: "name",
        label: "链接名",
        minWidth: 150
    },
    {
        prop: "old_link",
        label: "原地址",
        minWidth: 255
    },
    {
        prop: "short_link",
        label: "短链接",
        minWidth: 140
    },
    {
        prop: "effect_type",
        label: "生效方式",
        minWidth: 90
    },
    {
        prop: "effect_time",
        label: "生效时间止",
        minWidth: 160
    },
    {
        prop: "effect_count",
        label: "生效次数",
        minWidth: 140
    },
    {
        prop: "access_count",
        label: "访问次数",
        minWidth: 140
    },
    {
        prop: "action",
        label: "操作",
        fixed: "right",
        width: 270
    }
];
