<template>
    <el-dialog
        class="short-link-dialog"
        :title="title"
        :visible.sync="isShow"
        width="400px"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <el-form
            ref="shortLinkInfo"
            :model="form"
            label-width="120px"
            size="mini"
            :rules="rules"
            :key="key"
        >
            <el-form-item label="链接名" prop="name">
                <el-input v-if="isAdd || isEdit" v-model="form.name"></el-input>
                <span v-else>{{ form.name || "-" }}</span>
            </el-form-item>
            <el-form-item label="原地址" prop="old_link">
                <el-input
                    v-if="isAdd || isEdit"
                    type="textarea"
                    v-model="form.old_link"
                ></el-input>
                <span v-else>{{ form.old_link || "-" }}</span>
            </el-form-item>
            <el-form-item label="有效时间止" prop="effect_time">
                <el-date-picker
                    v-if="isAdd"
                    v-model="form.effect_time"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span v-else>{{ form.effect_time || "-" }}</span>
            </el-form-item>
            <el-form-item label="是否限制次数" prop="is_effect_count">
                <el-switch
                    v-if="isAdd"
                    v-model="form.is_effect_count"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
                <span v-else>{{ form.is_effect_count ? "是" : "否" }}</span>
            </el-form-item>
            <el-form-item
                v-show="form.is_effect_count"
                label="限制次数"
                prop="effect_count"
            >
                <el-input-number
                    v-if="isAdd"
                    v-model="form.effect_count"
                    :min="1"
                    :max="9999999"
                ></el-input-number>
                <span v-else>{{ form.effect_count || "-" }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" v-if="isAdd || isEdit" @click="handlerReset"
                >重置</el-button
            >
            <el-button
                type="primary"
                size="mini"
                v-if="isAdd || isEdit"
                @click="handlerSave"
                >保存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import copy from "copy-to-clipboard";
import moment from "moment";
import { addShortLinkApi, updateShortLinkApi } from "@/api/web/shortLink.js";

export default {
    name: "ShortLinkDialog",
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String
        },
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Object
        }
    },
    computed: {
        isShow: {
            get: function() {
                return this.visible;
            },
            set: function(v) {
                this.$emit("update:visible", v);
            }
        },
        isAdd() {
            return this.type === "add";
        },
        isEdit() {
            return this.type === "update";
        },
        rules() {
            let { name, old_link, effect_time, effect_count } = this.rulesAll;
            let rules = {};
            if (this.isAdd || this.isEdit) {
                rules = Object.assign(rules, {
                    name,
                    old_link
                });
                if (this.isAdd) {
                    rules = Object.assign(rules, {
                        effect_time
                    });
                }
                if (this.form.is_effect_count) {
                    rules = Object.assign(rules, {
                        effect_count
                    });
                }
            }
            return rules;
        },
        reqParams() {
            let params = {};
            params = Object.assign(params, {
                id: this.form.id,
                name: this.form.name,
                old_link: this.form.old_link,
                effect_time: this.form.effect_time,
                effect_type: "0"
            });
            if (this.form.is_effect_count) {
                params = Object.assign(params, {
                    effect_type: "1",
                    effect_count: this.form.effect_count
                });
            }
            return params;
        }
    },
    data() {
        return {
            key: 0,
            form: {},
            rulesAll: {
                name: [
                    {
                        required: true,
                        message: "请输入链接名",
                        trigger: "blur"
                    },
                    {
                        max: 255,
                        message: "长度在 255 个字符之内",
                        trigger: "blur"
                    }
                ],
                old_link: [
                    {
                        required: true,
                        message: "请输入原地址",
                        trigger: "blur"
                    }
                ],
                effect_time: [
                    {
                        required: true,
                        message: "请输入生效时间止",
                        trigger: "blur"
                    }
                ],
                effect_count: [
                    {
                        required: true,
                        message: "请输入限制访问次数",
                        trigger: "blur"
                    }
                ]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "一天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24);
                            picker.$emit("pick", date);
                        }
                    },
                    {
                        text: "一个周",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", date);
                        }
                    },
                    {
                        text: "一个月",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(
                                moment()
                                    .add(1, "M")
                                    .valueOf()
                            );
                            picker.$emit("pick", date);
                        }
                    },
                    {
                        text: "三个月",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(
                                moment()
                                    .add(3, "M")
                                    .valueOf()
                            );
                            picker.$emit("pick", date);
                        }
                    }
                ],
                disabledDate(date) {
                    return date <= new Date();
                }
            }
        };
    },
    methods: {
        setData() {
            if (this.isAdd) {
                this.$set(this, "form", {
                    name: "",
                    old_link: "",
                    effect_time: "",
                    is_effect_count: false,
                    effect_count: 1
                });
            } else {
                this.$set(
                    this,
                    "form",
                    Object.assign(
                        {
                            is_effect_count: this.effect_count > 0
                        },
                        this.data
                    )
                );
            }
        },
        handlerReset() {
            this.$refs["shortLinkInfo"].resetFields();
        },
        handlerOpen() {
            this.key++;
            this.setData();
        },
        handlerClose() {
            this.isShow = false;
        },
        handlerSave() {
            this.$refs["shortLinkInfo"].validate(valid => {
                if (valid) {
                    let action = undefined;
                    if (this.isAdd) {
                        action = addShortLinkApi;
                    } else if (this.isEdit) {
                        action = updateShortLinkApi;
                    } else {
                        return false;
                    }
                    action(this.reqParams)
                        .then(res => {
                            if (res.data.code == 0) {
                                this.$notify({
                                    title: "成功",
                                    message: "保存成功",
                                    type: "success"
                                });
                                this.showShortUrl(res.data.data);
                                this.handlerClose();
                                this.$emit("reload");
                            } else {
                                this.$notify.error({
                                    title: "失败",
                                    message: res.data.msg || "保存失败"
                                });
                            }
                        })
                        .catch(error => {
                            this.$notify.error({
                                title: "异常",
                                message: error
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        showShortUrl(url) {
            const h = this.$createElement;
            this.$msgbox({
                title: "短连接地址",
                message: h(
                    "p",
                    { style: "white-space: normal; overflow-wrap: break-word" },
                    url
                ),
                showCancelButton: true,
                confirmButtonText: "复制并关闭",
                cancelButtonText: "关闭",
                closeOnClickModal: false,
                closeOnPressEscape: false
            })
                .then(() => {
                    copy(url, {
                        debug: false,
                        message: "Press #{key} to copy"
                    });
                    this.$notify({
                        title: "成功",
                        message: "复制成功",
                        type: "success"
                    });
                    // 非Https不能用
                    // navigator.clipboard
                    //     .writeText(url)
                    //     .then(() => {
                    //         this.$notify({
                    //             title: "成功",
                    //             message: "复制成功",
                    //             type: "success"
                    //         });
                    //     })
                    //     .catch(err => {
                    //         this.$notify({
                    //             title: "失败",
                    //             message: err,
                    //             type: "error"
                    //         });
                    //     });
                })
                .catch(() => {});
        }
    }
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
    .el-input,
    .el-input-number {
        width: 100%;
    }
}
</style>
